// 导入axios
import axios from 'axios';
import {getToken} from '@/utils/token.js'
import router from '@/router'
import { Toast } from 'vant';

// 使用自定义配置新建一个axios 实例，对axios 做一些基础配置
const instance = axios.create({
    baseURL: 'http://wxgsm.henanmu.com/h5api/',
    timeout: 30000,
    headers: { 'enterprise': '001' }
});
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    //请求之前执行该函数， 一般在该处设置token 
    let token = getToken();
    if (token) {
        config.headers["token"] = token
    }
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//响应拦截器
instance.interceptors.response.use(response=>{
	//响应数据
	if(response.data.code==403){
		Toast.fail('登陆过期,请重新登陆'); 
		router.push('/login')
	}else if(response.data.code!==1){
		// Message({
		// 	message:response.data.message,
		// 	type:'error',
		// 	duration:2000
		// })
		Toast.fail(response.data.message);
	}
	return response;
},error=>{
	//响应数据错误
	// Message({
	// 	message:'error.message',
	// 	type:'error',
	// 	duration:2000
	// })
	Toast.fail(error.message);
	return Promise.reject(error)
})
export default instance