<template>
  <div id="app">
    <!-- 路由对应的组件存放到router-view中-->
    <router-view></router-view>
    <!-- 底部tabbar 组件 -->
    <AppTabBar v-show="$route.meta.isShowTabbar"></AppTabBar>
    
  </div>
</template>

<script>
// 引入底部tabbar 组件
import AppTabBar from './components/AppTabBar.vue';

export default {
  name: 'App',
  components: {
    AppTabBar,
  },
};
</script>

<style scoped>

</style>